import React from "react"
import { Link, graphql } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"

const linkEvent = clipName => {
  typeof window !== "undefined" &&
    typeof window.gtag !== "undefined" &&
    window.gtag("event", "refer_click_list", { clipName })
}

const IndexPage = ({ data }) => {
  const products = data.allProductsJson.edges
  return (
    <Layout>
      <main className="max-w-4xl flex-grow mx-auto flex flex-col justify-around">
        <div>
          <p className="px-4 mt-4 text-lg text-gray-700">
            Available now! Window grille fasteners for Andersen
            Narroline double hung Windows. These grille clips hold your muntin bars
            in place on your vintage windows. 
          </p>
        </div>          
        <h2 className="text-xl px-4 font-bold">Have questions? Check out our videos on Youtube</h2>
        <div className="flex flex-col sm:flex-row m-auto" >
          <div className="flex-1 m-2 mx-4">
            <iframe width="366" height="214" src="https://www.youtube.com/embed/w9zlL0QUATo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div className="flex-1 m-2 mx-4">
            <iframe width="366" height="214" src="https://www.youtube.com/embed/lBCiAbBh_4s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
        {products.map(edge => {
          const p = edge.node
          return (
            <div
              key={p.name}
              className=" bg-white rounded-md my-5 mx-1 sm:flex sm:flex-row-reverse sm:items-center"
            >
              <div className="p-4">
                <h1 className="p-2 text-2xl text-left text-blue-500 font-bold">
                  Andersen Window Replacement Clip -{" "}
                  <span style={{ whiteSpace: "nowrap" }}>{p.name}</span>
                </h1>
                <p className="px-2 mt-2 text-lg text-gray-700">
                  Original Model: {p.model}
                  <br />
                  Clip Dimensions: {p.dim}
                </p>
                <div className="p-2 sm:py-4">
                  <OutboundLink
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    href={p.buyURL}
                    onClick={() => {
                      linkEvent(p.name)
                    }}
                  >
                    Order now
                  </OutboundLink>
                </div>
                <div className="p-2 justify-end">
                  <Link to={`/products/${p.slug}`} className="text-blue-500">
                    ➡️ more details...
                  </Link>
                </div>
              </div>
              <img
                className="transform scale-75 sm:scale-100 w-full mx-auto rounded-md"
                src={p.image}
                alt={p.imageAlt}
              />
            </div>
          )
        })}
        <div className="p-2 sm:p-6 mx-1 bg-white rounded shadow-xs sm:mx-auto">
          <p className="m-1 sm:m-3 text-md text-gray-700">
            If you are here on this site it probably means you have a house with Andersen Window
            brand windows. You probably also know that the clips that hold these window grilles 
            in place are made of plastic. Over the decades of being in sunlight they become 
            brittle and crack. When trying to remove the wood grilles for cleaning 
            the clips can get stuck or often break.
          </p>
          <div className="py-2 sm:py-6 grid justify-items-center grid-cols-1 grid-rows-2 sm:grid-cols-2 sm:grid-rows-1 gap-4">
            <figure className="w-72">
              <StaticImage
                className="rounded-md"
                src="../images/andersen_window_grille.jpg"
                alt="Picture of Andersen Narroline double-hung window installed in 1980"
                placeholder="blurred"
                layout="fixed"
                width={300}
                height={400}
                />
              <figcaption className="py-3 text-sm font-light text-center">
                Picture of Andersen Narroline double-hung window installed in 1980
              </figcaption>
            </figure>
            <figure className="w-72">
              <StaticImage
                className="rounded-md"
                src="../images/window_grille_clip.jpg"
                alt="A close-up photo of the Andersen Window grille clip holding the wooden grille in place against the window glass"
                placeholder="blurred"
                layout="fixed"
                width={300}
                height={400}
              />
              <figcaption className="py-3 text-sm font-light text-center">
                A close-up photo of the original Andersen Window grille clip holding the wooden grille in place against the window glass
              </figcaption>
            </figure>
          </div>
          <p className="m-3 text-md text-gray-700">
            Since these window grille fasteners have all be discontinued by the manufacturer, we have carefully 
            measured and recreated the clips using CAD software. Then
            we reproduce them using 3D printing. All the window grille clips shown on this 
            site are reproductions of the plastic grille
            clips (muntin bar clips) used in Andersen Narroline double hung
            Windows. These clips are for windows that were manufactured in the 70's and 80's. The
            original clips have been discontinued by the manufacturer and you
            cannot find them anywhere but here. These clips are designed to be perfect replacement
            for the original part but are made from a durable white nylon
            plastic that is both flexible and strong. Available in white
            but you can spray paint these to match your window frame color.
          </p>
          <p className="m-3 text-md text-gray-700">
            We have set up an e-commerce shop through our 3D printing 
            manufacturer <OutboundLink href="https://www.shapeways.com/shops/madeparts">Shapeways</OutboundLink> this allows us to keep the cost for this product as low as possible. It also means that
            when you place an order through our <OutboundLink href="https://www.shapeways.com/shops/madeparts">Shapeways marketplace</OutboundLink> they will manufacturer and ship
            you the parts directly. Since anything you order are printed just for you, on demand, we 
            cannot offer any returns. Please make sure you are ordering the correct clips for your 
            window grilles. Feel free to contact us if you have questions.
          </p>
        </div>
        <div className="flex items-center p-6 mx-1 bg-white rounded shadow-xs sm:mx-auto">
          <p>
            If you have any questions send us an email here:&nbsp;
            <a href="mailto:parts@madeparts.com">parts@madeparts.com</a>
          </p>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery {
    allProductsJson {
      edges {
        node {
          slug
          name
          model
          image
          dim
          buyURL
          imageAlt
        }
      }
    }
  }
`

export default IndexPage
